<template>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Pickup Summary</h1>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Pick Request</button>
          </div>
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
          <div class="card">
              <div class="card-header">
                  <form v-on:keyup.enter="searchData">
                      <div class="row">
                          <div class="col-md-2">
                              <div class="form-group">
                                    <label for="title">{{ $t('globalTrans.merchant') }}</label>
                                    <v-select name="merchant_id"
                                        v-model="search.merchant_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= commonObj.merchantList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                              </div>
                          </div>
                          <div class="col-md-2">
                              <div class="form-group">
                                    <label for="title">Store </label>
                                    <v-select name="store_id"
                                        v-model="search.store_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= storeList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                              </div>
                          </div>
                          <div class="col-md-2" v-if="authUser.role_id <= 2">
                              <div class="form-group">
                                  <label for="title">{{ $t('globalTrans.hub') }}</label>
                                    <v-select name="hub_id"
                                        v-model="search.hub_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= commonObj.hubList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                              </div>
                          </div>
                          <div class="col-md-2">
                              <div class="form-group">
                                  <label for="title">Rider</label>
                                    <v-select name="rider_id"
                                        v-model="search.rider_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= commonObj.riderList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                              </div>
                          </div>
                          <div class="col-md-2">
                              <div class="form-group">
                                  <label for="start_date">Start Date</label>
                                  <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                              </div>
                          </div>
                          <div class="col-md-2">
                              <div class="form-group">
                                  <label for="end_date">End Date</label>
                                  <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                              </div>
                          </div>
                          <div class="col-md-2">
                              <div class="form-group" style="margin-top:28px;">
                                  <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData" ><i class="fa fa-search"></i></button>
                                  <button class="btn btn-warning btn-sm mr-2" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                 <a class="btn btn-warning btn-sm" target="_blank" :href="$baseUrl + 'excel-download/pickup?start_date='+search.start_date+'&end_date='+search.end_date+'&merchant_id='+search.merchant_id+'&hub_id='+search.hub_id+'&rider_id='+search.rider_id">
                                    <i class="fa fa-download"></i> Excel
                                 </a> 
                              </div>
                          </div>
                      </div>
                  </form>
              </div>
              <!-- <div class="card-body">
                  <b-overlay :show="loader">
                      <div class="table-responsive">
                          <table class="table table-bordered table-striped table-sm">
                              <thead>
                                  <tr class="text-center">
                                      <th>Pick Pending</th>
                                      <th>Pulsing Merchant</th>
                                      <th>Previous Pending</th>
                                      <th>Total Assign</th>
                                      <th>Pickup Van Assigned For</th>
                                      <th>Rider Assigned For</th>
                                      <th>Assigned From Previous Pending</th>
                                      <th>Assigned Pending</th>
                                      <th>Today's Pick Request</th>
                                      <th>Pick Agent</th>
                                  </tr>
                              </thead>
                              <tbody v-if="summary">
                                  <tr class="text-center text-bold">
                                      <td>{{ summary.pick_pending }}</td>
                                      <td>{{ summary.pulsing_merchant }}</td>
                                      <td>{{ summary.previous_pending }}</td>
                                      <td>{{ summary.total_assign }}</td>
                                      <td>{{ summary.pickup_van_parcel }} <br/> ({{ summary.pickup_van_merchant }} M)</td>
                                      <td>{{ summary.cycle_rider_parcel }} <br/> ({{ summary.cycle_rider_merchant }} M)</td>
                                      <td>{{ summary.assign_from_prev_pending }}</td>
                                      <td>{{ summary.assign_pending }}</td>
                                      <td>{{ summary.today_pick_request }}</td>
                                      <td>{{ summary.pic_agent }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </b-overlay>
              </div> -->
              <div class="card-body mt-20">
                <b-overlay :show="loader">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr class="text-center">
                                    <th>{{ $t('globalTrans.merchant') }}</th>
                                    <th>{{ $t('globalTrans.total_parcel_req') }}</th>
                                    <th>Rider Picked</th>
                                    <th>Actual Picked</th>
                                    <th>Address</th>
                                    <th>{{ $t('globalTrans.hub') }}</th>
                                    <th>{{ $t('globalTrans.rider') }}</th>
                                    <th>{{ $t('globalTrans.date') }}</th>
                                    <th>Time</th>
                                    <th>Age</th>
                                    <th>Scaned At</th>
                                    <th>Updated At</th>
                                    <th>{{ $t('globalTrans.status') }}</th>
                                    <th style="text-align:center;">{{ $t('globalTrans.action') }}</th>
                                </tr>
                            </thead>
                            <tfoot>
                            <tr class="text-center">
                                    <th>{{ $t('globalTrans.merchant') }}</th>
                                    <th>{{ $t('globalTrans.total_parcel_req') }}</th>
                                    <th>Rider Picked</th>
                                    <th>Actual Picked</th>
                                    <th>Address</th>
                                    <th>{{ $t('globalTrans.hub') }}</th>
                                    <th>{{ $t('globalTrans.rider') }}</th>
                                    <th>{{ $t('globalTrans.date') }}</th>
                                    <th>Time</th>
                                    <th>Age</th>
                                    <th>Scaned At</th>
                                    <th>Updated At</th>
                                    <th>{{ $t('globalTrans.status') }}</th>
                                    <th>{{ $t('globalTrans.action') }}</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr v-for="(pickup,key) in listData" :key="key">
                                    <td style="width:10%">{{ pickup.merchant_business }}</td>
                                    <td style="width:8%; text-align:center">{{ pickup.total_parcel }}</td>
                                    <td style="width:8%; text-align:center">{{ pickup.rider_picked }}</td>
                                    <td style="width:5%; text-align:center">{{ pickup.picked_up }}</td>
                                    <td style="width:18%">{{ pickup.address }}</td>
                                    <td style="width:8%">{{ pickup.hub_name }}</td>
                                    <td style="width:8%">{{ pickup.rider_name }}</td>
                                    <td style="width:8%" class="text-center">{{ pickup.date }}</td>
                                    <td style="width:10%" class="text-center">{{ getTimeSlot(pickup.time) }}</td>
                                    <td style="width:8%" class="text-center">
                                        <slot v-if="pickup.status == 1">
                                            {{ pickup.updated_at | dateformat }} at {{ pickup.updated_at | timeformat }}
                                        </slot>
                                        <slot v-else>
                                            {{ pickup.time_gap }}
                                        </slot>
                                    </td>
                                    <td style="width:8%" class="text-center">{{ pickup.scaned ? (pickup.scaned | dateformat + ' at '+ pickup.scaned | timeformat) : '' }}</td>
                                    <td style="width:8%" class="text-center">{{ pickup.updated_at | dateformat }} at {{ pickup.updated_at | timeformat }}</td>
                                    <td style="width:8%" :class="'text-center pickup-status-' + pickup.status">{{ getStatus(pickup.status) }}</td>
                                    <td style="width:15%; text-align:center;">  
                                        <button v-if="pickup.status == 1 && pickup.rider != null" class="btn btn-success btn-sm mr-1" @click="statusUpdate(2, key, pickup)" :title="$t('globalTrans.active')"><i class="fas fa-check"></i></button>
                                        <button class="btn btn-primary btn-sm mr-1" @click="edit(pickup)" :title="$t('globalTrans.edit')"><i class="fas fa-pencil-alt"></i></button>
                                        <button class="btn btn-warning btn-sm mr-1" @click="trash(pickup.id)" :title="$t('globalTrans.delete')"><i class="fas fa-trash"></i></button>
                                        <button v-if="authUser.role_id <= 2" class="btn btn-danger btn-sm" @click="destroy(key, pickup.id)" title="Remove"><i class="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mt-3">
                        <b-pagination
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                        />
                    </div>
                </b-overlay>
            </div>
          </div>
      </div>
    </section>
    <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" id="addHubModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <form v-on:keyup.enter="createPickup">
                <b-overlay :show="loader">
                    <div class="modal-content">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">{{ $t('globalTrans.addNew') }} {{ $t('globalTrans.pickup') }}</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(createPickup)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="merchant_id">{{ $t('globalTrans.merchant') }} <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="merchant_id"
                                                        v-model="form.merchant_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.merchantList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                    
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Store" vid="store_id" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="store_id">Store</label>
                                                    <v-select name="store_id"
                                                        v-model="form.store_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= storeList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                    
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Total Parcel" vid="total_parcel" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="total_parcel">{{ $t('globalTrans.total_parcel_req') }} <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="total_parcel" v-model="form.total_parcel" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div> 
                                              </ValidationProvider>                       
                                        </div>
                                        <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Source Hub" vid="hub_id" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="hub_id">Source {{ $t('globalTrans.hub') }} </label>
                                                    <v-select name="hub_id"
                                                        v-model="form.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>  
                                            </ValidationProvider>                      
                                        </div> -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Rider" vid="rider_id" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="rider_id">{{ $t('globalTrans.rider') }} <span class="text-danger" title="Required">*</span></label>                                        
                                                    <v-select name="rider_id"
                                                        v-model="form.rider_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.riderList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                    
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Time" vid="time" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="time">Time <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="time"
                                                        v-model="form.time"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= timeSlotList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>  
                                            </ValidationProvider>                      
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="address" v-model="form.address" class="form-control" readonly/>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>     
                                            </ValidationProvider>                   
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ValidationProvider name="Note" vid="note" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="note">Note </label>
                                                    <textarea id="note" v-model="form.note" class="form-control"> </textarea>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>     
                                            </ValidationProvider>                   
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="createPickup">{{ $t('globalTrans.submit') }}</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">{{ $t('globalTrans.cancel') }}</button>
                        </div>
                    </div>
                </b-overlay>
            </form>
        </div>
    </div>
    <div v-if="editModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <b-overlay :show="loader">
                <form v-on:keyup.enter="updatePickup">
                    <b-overlay :show="loader">
                        <div class="modal-content">
                            <div class="modal-header bg-info text-white">
                                <h5 class="modal-title text-center w-100">{{ $t('globalTrans.update') }} {{ $t('globalTrans.pickup') }}</h5>
                                <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                    <form @submit.prevent="handleSubmit(updatePickup)">
                                        <input type="hidden" name="id" v-model="form_edit.id" />
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="merchant_id">{{ $t('globalTrans.merchant') }} <span class="text-danger" title="Required">*</span></label>
                                                        <v-select name="merchant_id"
                                                            v-model="form_edit.merchant_id"
                                                            label="text"
                                                            :reduce="item => item.id"
                                                            :options= commonObj.merchantList
                                                            :placeholder="$t('globalTrans.select')"
                                                        />
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>       
                                                </ValidationProvider>                 
                                            </div>                                    
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Store" vid="store_id" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="store_id">Store</label>
                                                    <v-select name="store_id"
                                                        v-model="form_edit.store_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= storeList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                    
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Total Parcel" vid="total_parcel" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="total_parcel">{{ $t('globalTrans.total_parcel_req') }} <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="total_parcel" v-model="form_edit.total_parcel" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div> 
                                              </ValidationProvider>                       
                                        </div>
                                        <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Source Hub" vid="hub_id" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="hub_id">Source {{ $t('globalTrans.hub') }} </label>
                                                    <v-select name="hub_id"
                                                        v-model="form_edit.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>  
                                            </ValidationProvider>                      
                                        </div> -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Rider" vid="rider_id" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="rider_id">{{ $t('globalTrans.rider') }} <span class="text-danger" title="Required">*</span></label>                                        
                                                    <v-select name="rider_id"
                                                        v-model="form_edit.rider_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.riderList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                    
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Time" vid="time" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="time">Time <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="time"
                                                        v-model="form_edit.time"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= timeSlotList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>  
                                            </ValidationProvider>                      
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub Received" vid="rider_picked" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="rider_picked">Hub Received <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="rider_picked" v-model="form_edit.rider_picked" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div> 
                                              </ValidationProvider>                       
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="address" v-model="form_edit.address" class="form-control" readonly/>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>     
                                            </ValidationProvider>                   
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ValidationProvider name="Note" vid="note" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="note">Note </label>
                                                    <textarea id="note" v-model="form_edit.note" class="form-control"> </textarea>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>     
                                            </ValidationProvider>                   
                                        </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-info" @click.prevent="updatePickup">{{ $t('globalTrans.submit') }}</button>
                                <button type="button" class="btn btn-danger" @click="cancelModal">{{ $t('globalTrans.cancel') }}</button>
                            </div>
                        </div>
                    </b-overlay>
                </form>
            </b-overlay>
        </div>
    </div>
    <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-default" role="document">
            <div class="modal-content">
                <div class="modal-header bg-info text-white">
                <h5 class="modal-title text-center w-100">{{ $t('globalTrans.deleteConfirmation') }}</h5>
                </div>
                <div class="modal-body">
                    <input type="hidden" name="del_id" />
                    <h3 class="text-center">{{ $t('globalTrans.areYouSureToDelete') }}</h3>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="destroyPickup">{{ $t('globalTrans.yes') }}</button>
                    <button type="button" class="btn btn-danger" @click="cancelModal">{{ $t('globalTrans.no') }}</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
  import config from '@/config' 
  import moment from 'moment'  
  export default {
    name:'Pickup',
    data() {
      return {
        loader: false,
        search : {
          merchant_id: '',
          store_id: '',
          hub_id: '',
          rider_id: '',
          start_date: moment().subtract(1,'d').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD')
        },
        summary: null,
        form:{
            merchant_id : '',
            total_parcel : '',
            hub_id : '',
            rider_id : '',
            time: '',
            address : '',
            note : ''
        },
        form_edit:'',
        errors:[],
        riderList:[],
        createModal:false,
        editModal:false,
        deleteModal:false,
        pickup_id:'',
        key:'',
        timeSlotList: [
            { id:1, text: '12:00 PM to 4:00 PM'},
            { id:2, text: '4:00 PM to 8:00 PM'},
            { id:3, text: '8:00 PM to 12:00 PM'}
        ],
        pagination: {
            currentPage: 1,
            totalRows: 0,
            perPage: this.$store.state.commonObj.perPage,
            slOffset: 1
        },
        storeList: []
      }
    },
    created () {
      if (this.$route.query) {
        Object.assign(this.search, { id: this.$route.query.id })
      }  
    //   this.loadSummary()
      this.loadData()
    },
    computed : {
      commonObj () {
        return this.$store.state.commonObj
      },
      stateReload () {
          return this.$store.state.stateReload
      },
      listData () {
          return this.$store.state.list
      },
      authUser () {
        return this.$store.state.authUser
      }
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                // this.loadSummary()
                this.loadData()
            }
        },
        'form.total_parcel': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.riderList = this.getCoveredVanRiderList()
            }
        },
        'form_edit.total_parcel': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.riderList = this.getCoveredVanRiderList()
            }
        },
        'search.merchant_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.storeList = this.geStoreList(newVal)
            }
        },
        'form.merchant_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.storeList = this.geStoreList(newVal)
                this.form.address = this.getMerchantAddress(newVal)
            }
        },
        'form_edit.merchant_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.storeList = this.geStoreList(newVal)
                this.form_edit.address = this.getMerchantAddress(newVal)
            }
        },
        'form.store_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.form.address = this.getStoreAddress(newVal)
            }
        },
        'form_edit.store_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.form_edit.address = this.getStoreAddress(newVal)
            }
        },
        '$route.query.id': function (newVal, oldVal) {
            if(newVal != oldVal) {
                Object.assign(this.search, { id: this.$route.query.id })
                this.loadData()
            }
        }
    },
    methods: {
        reload () {
            this.search = Object.assign({}, {
                merchant_id: '',
                store_id: '',
                hub_id: '',
                rider_id: '',
                start_date: moment().subtract(1,'d').format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD')
            })
        },
        searchData () {
            // this.loadSummary()         
            this.loadData()         
        },
        async loadSummary () {
            this.loader = true
            const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search
            const response = await config.getData('/pickup/summary', params)
            this.loader = false
            this.summary = response.data          
        },
        async loadData(){     
            this.loader = true
            const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })               
            const response = await config.getData('/pickup', params)
            this.loader = false
            if (response.status == 200) {
                this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                this.paginationData(response.data)  
            } else {
                this.$store.dispatch('setList', [])
            }
        },
        getRelationalData (data) {
            var rightNow = moment().format("YYYY-MM-DD HH:mm:ss")
            var today = moment().format("YYYY-MM-DD")
            const datas = data.map(item => {
                const createdAt =  moment(item.created_at, 'DD-MM-YYYY hh:mm:ss')
                const updatedAt =  moment(item.updated_at, 'DD-MM-YYYY hh:mm:ss')
                const merchant = this.commonObj.merchantList.find(el => el.id == item.merchant_id)
                const hub = this.commonObj.hubList.find(el => el.id == item.hub_id)
                const rider = this.$store.state.commonObj.riderList.find(el => el.id == item.rider_id)
                const tmpAge = (today == item.date) ? (moment(rightNow).diff(item.created_at, 'hours') + ' hour ' + moment(rightNow).diff(item.created_at, 'minutes') + ' minutes') : ''                
                const timeGap =  updatedAt.diff(createdAt, 'hours') +  ' hour ' + updatedAt.diff(createdAt, 'minutes') + ' minutes'
                const merchantObj = { merchant_business : typeof merchant !== 'undefined' ? merchant.text : '', age: tmpAge, time_gap: timeGap }
                const hubObj = { hub_name : typeof hub !== 'undefined' ? hub.text : '' }
                const riderObj = { rider_name : typeof rider !== 'undefined' ? rider.text : '' }

                return Object.assign({}, item, merchantObj, hubObj, riderObj)
            })
            return datas
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        showCreateModal(){
            this.createModal = true;
            document.body.classList.add("modal-open");
        },
        async createPickup () {
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/pickup/store', this.form)
            this.loader = false
            if (parseInt(response.status) == 201) {
                this.form.merchant_id = this.form.total_parcel = this.form.hub_id = this.form.rider_id = this.form.time = this.form.address = this.form.note = ''
                this.createModal = false
                this.$toast.success({
                    title: 'Success',
                    message: 'Pickup created successfully',
                    color: '#218838'
                })  
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: response.message,
                    color: '#dc3545'
                })
                this.$refs.form.setErrors(response.errors)
            }
            this.$store.dispatch('stateReload', false)
            
        },
        cancelModal(){
            this.createModal = false;
            this.editModal = false;
            this.deleteModal = false;
            document.body.classList.remove("modal-open");
        },
        edit(pickup){
            this.editModal = true;
            this.form_edit = pickup;
            document.body.classList.add("modal-open");
        },
        async updatePickup(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/pickup/update', this.form_edit)                
            this.loader = false
            if (response.status == '201') {
                this.editModal = false;    
                this.$store.dispatch('removeFromNavbarPickup', this.form_edit.id)
                this.$toast.success({
                    title: 'Success',
                    message: 'Pickup updated successfully',
                    color: '#218838'
                })
            } else {
                this.$refs.form.setErrors(response.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })
            }
            this.$store.dispatch('stateReload', false)                
        },
        destroy(key, pickup_id){ 
            this.pickup_id = pickup_id;
            this.key = key;
            this.deleteModal = true;
        },
        destroyPickup(){
            config.deleteData('/pickup/destroy/'+ this.pickup_id)
            .then(() => {
                this.pickups.data.splice(this.key, 1);
                this.deleteModal = false;
            });
        },
        async statusUpdate(status, key, pickup){ 
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const params = { id: pickup.id, status: status }
            const response = await config.postData('/pickup/status-update', params)                            
            this.loader = false
                this.$store.dispatch('stateReload', false)
                if (status == 2) {
                    this.$store.dispatch('removeFromNavbarPickup', pickup.id)
                }
            if (response.status == 201) {
                this.$toast.success('Status update successfully') 
            } else {
                this.$toast.error('Sorry, something went wrong')
            }
        },
        getCoveredVanRiderList () {
            const riderList = this.$store.state.commonObj.riderList
            if (this.form.total_parcel >= 250) {
                return riderList.filter(item => item.vehicle == 'Covered Van')
            } else {
                return riderList
            }
        },
        async trash (itemId) {
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const params = { id: itemId}
            const response = await config.getData('/pickup/trash', params)                
            this.loader = false
            if (response.status == '201') {  
                this.$toast.success(response.message)   
            } else {
                this.$toast.error(response.message)  
            }
            this.$store.dispatch('stateReload', false)  
        },
        geStoreList (merchantId) {
            return this.commonObj.storeList.filter(el => el.merchant_id == merchantId)
        },
        getStoreAddress (storeId) {
            const store = this.commonObj.storeList.find(el => el.id == storeId)
            return typeof store !== 'undefined' ? store.address : ''
        },
        getMerchantAddress ( merchantId) {
            const merchant = this.commonObj.merchantList.find(el => el.id == merchantId)
            return typeof merchant !== 'undefined' ? merchant.address : ''
        },
        getStatus (status) {
            if (status == 1) {
                return 'Pending'
            } else if (status == 2) {
                return 'Accepted'
            } else if (status == 3) {
                return 'Deleted'
            } else if (status == 4) {
                return 'Rider Picked'
            }
        },
        getTimeSlot (time) {
            if (time == 1) {
                return '12:00 PM to 4:00 PM'
            } else if (time == 2) {
                return '4:00 PM to 8:00 PM'
            } else if (time == 3) {
                return '8:00 PM to 12:00 PM'
            }
        }
    }
  }
</script>

<style scoped>
    .form-group select2 {
        width: 218px !important;
    }
</style>